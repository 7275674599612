import ContentGrid from "./ContentGrid";
import photo1 from './assets/services/photo1.png';
import photo2 from './assets/services/photo2.jpeg';
import photo3 from './assets/services/photo3.png';
import photo4 from './assets/services/photo4.png';

export default function OurServices () {

    const servicesContent = [
        { "rowOne" : [
            { "text": "hair cuts" },
            { "image": photo1, alt:"shears hanging on a wall", "id":"shears" },
            { "text" : "styling" }
      ]},
          { "rowTwo": [
            { "image" : photo2, "alt": "styling tools lying on a tray", "id":"styling" },
            { "text" : "waxing / shaving / tinting" }, 
            { "image" : photo3, "alt":"sleek pair of eyebrow trimmers", "id":"trimmers" },
      ]},
        { "rowThree" : [
            { "text" : "color" },
            { "image" : photo4, "alt":"a shaving cup and implements on a towel.", "id":"shave" },
            { "text" : "smoothing & relaxer treatments"}
        ]
        }
    ];

    return (
        <>
        <h2 className="title-black" id="our-services-page">Our Services</h2>
        <div className="App-page" id="our-services">
            <div className="content-container" id="gallery-content"  multirow="true">
                <ContentGrid data={servicesContent} type="services" />
            </div>
        </div>
        </>
    )
}