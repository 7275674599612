import { useState } from 'react';
import ContentGrid from './ContentGrid';
import photo1 from './assets/gallery/photo1.jpg';
import photo2 from './assets/gallery/photo2.jpg';
import photo3 from './assets/gallery/photo3.jpg';
import photo4 from './assets/gallery/photo4.jpg';
import photo5 from './assets/gallery/photo5.png';
import photo6 from './assets/gallery/photo6.jpg';
import photo7 from './assets/gallery/photo7.jpg';
export default function Gallery () {

    let galleryContent = [
      { "rowOne" : [
        { "image": photo1, "alt":"a smiling customer with great hair.", "id":"0" },
        { "image": photo2, "alt": "a happy customer with great hair.", "id":"1" },
        { "image": photo3, "alt":"a happy customer with great hair color.", "id":"2" },
        { "image": photo4, "alt":"a customer with a great haircut.", "id":"3" },
        { "image" : photo5, "alt":"a smiling lawtiwa customer.","id":"4" },
        { "image" : photo6, "alt":"a smiling customer with great hair.", "id":"5" },
        { "image": photo7, "alt":"a young customer with a great haircut.", "id":"6" }      
  ]}
  ];

  const [ displayContent, setDisplayContent ] = useState(galleryContent);

  function moveElementToEnd(id) {
    // Create a copy of galleryContent to avoid mutating the original data
    const updatedGalleryContent = [...galleryContent];
    // Extract the rowOne array from the first element of galleryContent
    const rowOne = updatedGalleryContent[0].rowOne;
    // Find the index of the element with the matching id
    const index = rowOne.findIndex(item => item.id === id);
    if (index !== -1) {
        // Remove the element with the matching id from the rowOne array and store it
        const removedElement = rowOne.splice(index, 1)[0];
        // Append the removed element to the end of the rowOne array
        rowOne.push(removedElement);
    }
    // Return the updated galleryContent
    return updatedGalleryContent;
}


function handleElementClick (e) {
  e.preventDefault();
  setDisplayContent(moveElementToEnd(e.target.dataset.id));
};

  

    return(
        <>
        <h2 className="title-black" id="gallery-page">Gallery</h2>
        <div className="App-page" id="gallery">
            <div className="content-container" id="gallery-content" multirow="true">
              <ContentGrid data={displayContent} handleElementClick={handleElementClick} type="gallery" />
            </div>
            
        </div>
        </>
    )
}