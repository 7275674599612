import email from './assets/svg/email.svg';
import map from './assets/visit/map.png';
import location from './assets/svg/location.svg';
import facebook from './assets/svg/facebook.svg';
import instagram from './assets/svg/instagram.svg';
import phone from './assets/svg/phone.svg';


export default function VisitUs () {
    return (
        <>
            <h2 className="title-black" id="visit-us-page">Visit Us</h2>
            <div className="App-page" id="visit-us"> 
                <div className='content-container' id="visit-us-container">
                    <div className="map-container" id="visit-img-container">
                        <a className="contact-list" href="https://www.google.com/maps/place/Lawtiwa+Barbersalon/@47.450355,-122.3513626,15z/data=!4m2!3m1!1s0x0:0xe9a4878c8e5151b8?sa=X&ved=2ahUKEwj7w8SBiceEAxUeBzQIHUntCOUQ_BJ6BAgVEAA" target="_blank" rel="noreferrer">
                            <img className="map" src={map} alt="A map with a dropped pin labeled Lawtiwa Barbersalon." />
                            <p className="contact-list"><img className="icon" src={location} alt="A dropped pin icon." /> 17002 12th Place SW, Normandy Park, Washington 98166</p>
                        </a>
                    </div>
                    <div className='visit-us-msg'>
                        <div className='msg-container' id="visit-us-msg">
                        
                            <div className="contact-info">
                                <h2 className='header-black'>Contact Us</h2>
                                <ul className='contact-list' id="contact-container">
                                <li><a href="tel:+12067551650"><img className='contact-icon-responsive' src={phone} alt="Icon of a phone." /></a><p className="contact-text"> Shop/Cell: </p><a href="tel:+12067551650"><p className="contact-text">206.755.1650</p></a><p className="contact-text">(text for faster service)</p></li>
                                <li><a href="mailto:deannasyourstylist@gmail.com"><img className='contact-icon-responsive' src={email} alt="Icon of an envelope." /></a><p className="contact-text">Email: </p><a href="mailto:deannasyourstylist@gmail.com"><p className="contact-text"> deannasyourstylist@gmail.com</p></a></li>
                                </ul>
                            </div>
                            <div className="social-info">
                                
                                <ul className='contact-list' id="social-links">
                                    <li><a className="icon-social" href="https://www.instagram.com/lawtiwabarbersalon/" target="_blank" rel="noreferrer"><img src={instagram} className='contact-icon' alt="Instagram logo." /></a></li>
                                    <li><a className="icon-social" href="https://www.facebook.com/nezpercetribe" target="_blank" rel="noreferrer" ><img src={facebook}className='contact-icon' alt="Facebook logo." /></a></li>
                                </ul>
                            </div>
                            <div className="hours">
                                <h2 className='header-black'>Hours</h2>
                                <ul className="contact-list">
                                    <li>Sun: Closed</li>
                                    <li>Mon: Closed</li>
                                    <li>Tue: 11:00 AM - 03:00 PM</li>
                                    <li>Wed: 11:00 AM - 06:00 PM</li>
                                    <li>Thu: 11:00 AM - 06:00 PM</li>
                                    <li>Fri: 10:00 AM - 07:00 PM</li>
                                    <li>Sat: 08:00 AM - 03:00 PM</li>
                                </ul>
                            </div>
                            <div>
                            <h2 className='header-black'>Cancellation Policy</h2>
                            <p className="contact-list">We charge 100% of the service cost for cancellations within 48 hours of your appointment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}