import './hamburger.css';
import { useState } from 'react';

export default function NavBar () {

    const [isToggleOn, setIsToggleOn] = useState(false);

    const toggleState = () => {
        setIsToggleOn(isToggleOn => !isToggleOn);
        
    }

    const handleNavLinkClick = (e) => {
        toggleState();    
      };



    return (
        <div className="navigation-bar-container">

            {/* hamburger menu */}
            <div className="hamburger" onClick={toggleState}>
                <button className="dots" on={isToggleOn ? "true" : "false"}><span></span></button>
            </div>
            {/* hamburger menu end */}

            <nav expanded={isToggleOn ? "true" : "false"} className="navigation-bar">
                <ul id="nav-bar">
                    <li id="home-link" onClick={handleNavLinkClick} className="navigation-link"><a href="#home-page">Home</a></li>
                    <li id="gallery-link" onClick={handleNavLinkClick} className="navigation-link"><a href="#gallery-page">Gallery</a></li>
                    <li id="services-link" onClick={handleNavLinkClick} className="navigation-link"><a href="#our-services-page">Our Services</a></li>
                    <li id="about-link" onClick={handleNavLinkClick} className="navigation-link"><a href="#about-page">About</a></li>
                    <li id="visit-link" onClick={handleNavLinkClick} className="navigation-link"><a href="#visit-us-page">Visit Us</a></li>
                </ul>
            </nav>
        </div>
    )
}