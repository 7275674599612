import './App.css';
import './mobile.css';
import Home from './components/Home';
import About from './components/About';
import OurServices from './components/OurServices';
import VisitUs from './components/VisitUs';
import Header from './components/Header';
import Gallery from './components/Gallery';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='App-content'> 
        <Home />
        <Gallery />
        <OurServices />
        <About />
        <VisitUs />
        </div>
    </div>
  );
}

export default App;
