import logo from '../logo.svg';
import lawtiwa from './assets/svg/law-vect.svg';
import homeImage from './assets/home/cutting.jpg';

export default function Home () {
    return(
        
        <>
        <div><h2 className="title-black" id="home-page">Home</h2></div>
            <div className="App-page" id="home">
                <div className="content-container">
                    <div className="img-container" id="img-container-home">
                        <img className="main-img" id="home-img" src={homeImage} alt="Stylist cutting/styling a client's hair." />
                    </div>
                    <div className="messsage-container" id="home-message">
                        <span className="overlay" id="home">
                            <h2 className="title">Welcome to</h2>
                            <img className="logo" src={logo} alt="A colorfully patterned pair of hair cutting shears" />
                            <img id="logo-text" src={lawtiwa} alt="Lawtiwa" />
                            <h2 className="title">Barbersalon</h2>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}