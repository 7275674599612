import aboutPhoto from './assets/about/deanna_pic.jpg'

export default function About () {

    const aboutText = [
        {"Deanna Owner & Operator":["25 years in the industry in Fall 2024.",

        "Deanna was inspired by watching her step-mother Joanne cut hair. Her first clients were her barbies.",
        
        "Deanna has worked in salons and barbershops throughout Seattle and Bellevue."
    ]},
    
    {"Lawtiwa": [
        "LAWTIWA  pronounced: LOW (as in vow),  TEE (like a golf tee), WAH  (as in saw), means friend in the Nez Perce language.", 

        "We specialize in Curly Hair, Cuts, Colors, Fades, Waxing, Shaving, Smoothing and Straightening treatments, Relaxers, Permanent Waves & Afro Styling.",
        
        "Afro Indigenous & Nez Perce tribal member owned.",
        
        "We provide inclusive services to ALL genders, ages, ethnicities and hair textures.",
        
        "This shop is an active ally of the LGBTQ + community and seeks to promote diversity.",

        "Lawtiwa Barbersalon opened in April  2018.", 
        
        "Lawtiwa Barbersalon is now located in her in-home studio, since July 2023. She looks forward to doing your hair and welcoming you to the space." 
        ]}
]

const renderAboutText = () => (
    <div className="contact-list">
        {aboutText.map((item, index) => (
         
                Object.entries(item).map(([key, values]) => (
                    <div className="hours" id="about" key={key}>
                        <h2 className="section-title-black">{key}</h2>
                        <ul className='contact-list' id="about">
                            {values.map((value, index) => (
                                <li key={index}><p className="about-text">{value}</p></li>
                            ))}
                        </ul>
                    </div>
                ))
            
        ))}
    </div>
);

    
    return (
        <>
            <div><h2 className="title-black" id="about-page">About</h2></div>
            <div className="App-page" id="about">
            
            <div className="content-container" id="about-content">
                <span className='overlay' id="about"></span>
                <div className="messsage-container" id="message-container-about">
                    {renderAboutText()}
                </div>
                <div className="img-container" id="img-container-about">
                    <img className="main-img" id="about-img" src={aboutPhoto} alt="Stylist cutting/styling a client's hair." />
                </div>
                
            </div>
            </div>
        </>
    )
}