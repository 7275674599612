import { v4 as uuidv4 } from 'uuid';

export default function ContentGrid({ data, type, handleElementClick }) {
    const rowKey = uuidv4();
    const divKey = uuidv4();

    return data.map((rowObject, rowIndex) => { // Adding rowIndex parameter
        const [key, objects] = Object.entries(rowObject)[0];

        // Generate <div> elements for each row
        return (
            <div key={type + key + rowKey} className="row-container" id={`${type}-row`}>
                {objects.map((item) => { // Adding itemIndex parameter
                    // Map over the key-value pairs of each item
                    if (item.text) {
                        // Generate <p> element for text items
                        return (
                            <div key={item.text + divKey} className={`${type}-p-container`}>
                                <p className={`${type}-gallery-msg`} key={`text-${item.text}`}>
                                    {item.text}
                                </p>
                            </div>
                        );
                    } else if (item.image) {
                        // Generate <img> element for image items
                        return (
                            <div key={item.image + divKey} className={`${type}-img-container`}>
                                <img
                                    onClick={handleElementClick}
                                    className={`${type}-gallery-img`}
                                    key={`image-${item.image}`}
                                    src={item.image}
                                    alt={item.alt}
                                    data-id={`${item.id}`}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    });
}
