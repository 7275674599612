import NavBar from "./NavBar";

export default function Header () {

    
    return (
        <header className="App-header">
            <span className="button-container">
                <a href="https://squareup.com/appointments/book/e4a7f1f7-99d8-4730-91c0-6d5686265bf0/1JXSNNBK9FRZY/start" rel="nofollow" ><button>Book an appointment</button></a>
            </span>
            <nav>
                <NavBar />
            </nav>
        </header>
    )
}